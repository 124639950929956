import React, { useEffect, useState, useRef } from 'react';
import './files.css';
import { UserAuth } from '../../context/AuthContext';
import OneUserFiles from './OneUserFiles';
import OneFile from './OneFile';

import { db, storage, functions } from "../../firebase";
import {
    doc,
    collection,
    setDoc,
    updateDoc,
    serverTimestamp,
    addDoc,
    arrayUnion,
    increment,
    writeBatch,
    arrayRemove
} from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchIcon from './searchicon.svg';

import { motion, AnimatePresence } from 'framer-motion';
import AddMemberSVG from './adduser.svg';

import SoundType from './TypeSound.png';
import PhotoType from './TypePhoto.png';
import VideoType from './TypeVideo.png';
import Notes from './Notes';
import NoteSVGWithNotification from './NoteSvg.svg';
import NoteSVG from './NoteSVGWON.svg'

import ListSettingSVG from './ListSettingSVG.svg';
import GridSettingSVG from './GridSettingSVG.svg';

import SkeletonSound from '../invites/SkeletonSound3.jpg';
import { httpsCallable } from 'firebase/functions';
import OneHorizontalFile from '../invites/OneHorizontalFile';

import { getAudioLufs } from './audioAnalysis';



// ─────────────────────────────────────────────────────────────
//  1) Import your DataContext
// ─────────────────────────────────────────────────────────────
import { useDataContext } from '../../context/DataContext';
import SideBarShareLinkButton from '../useful/SideBarShareLinkButton';

const Files = () => {
    const { user } = UserAuth();

    const fileUploadInputRef = useRef(null);
    // ─────────────────────────────────────────────────────────────
    //  2) Destructure needed data from DataContext
    // ─────────────────────────────────────────────────────────────
    const { invites, workspaces, loading: contextLoading } = useDataContext();

    const [selectedFileType, setSelectedFileType] = useState('All files');
    const [files, setFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [filesPendingRename, setFilesPendingRename] = useState([]);

    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [workspaceName, setWorkspaceName] = useState('');

    const [isDragging, setIsDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [isHoveringFileTypeSelector, setIsHoveringFileTypeSelector] = useState(false);

    const [noteNotification, setNoteNotification] = useState(false);

    const [showEmailInput, setShowEmailInput] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { id: workspaceId } = useParams();


    // --- VIEW MODE ---
    const [viewMode, setViewMode] = useState("list"); // "list" or "grid"
    const emailInputRef = useRef(null);

    // State for Notes panel
    const [showNotesPanel, setShowNotesPanel] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    const [showShareMenu, setShowShareMenu] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const shareButtonRef = useRef(null);

    const [showExtraHover, setShowExtraHover] = useState(false);

    // Generate link if not already generated
    useEffect(() => {
        if (showShareMenu && !inviteLink) {
            generateInviteLink();
        }
    }, [showShareMenu, inviteLink]);

    const generateInviteLink = async () => {
        try {
            const token = Math.random().toString(36).substr(2);
            const inviteRef = collection(db, 'projectInvites');
            // Instead of addDoc, we can do setDoc with the token as the doc ID
            await setDoc(doc(inviteRef, token), {
                token,
                fileId: workspaceId,
                isUsed: false,
                createdAt: serverTimestamp()
            });
            const link = `${window.location.origin}/projectinvite/${token}`;
            setInviteLink(link);
        } catch (error) {
            console.error('Error generating invite link:', error);
        }
    };

    const handleAddUserByEmailClick = () => {
        // If the textbox is not shown, show it
        if (!showEmailInput) {
            setShowEmailInput(true);
            setTimeout(() => emailInputRef.current?.focus(), 0);
        }
        // Otherwise, if user typed something, add them
        else if (newUserEmail) {
            handleAddUser();
        }
    };


    // ─────────────────────────────────────────────────────────────
    //   FILE TYPE SELECT / UI
    // ─────────────────────────────────────────────────────────────
    const handleFileTypeSelect = (fileType) => {
        if (selectedFileType === fileType) {
            setSelectedFileType('All files');
        } else {
            setSelectedFileType(fileType);
        }
    };

    const isButtonActive = (type) => {
        return selectedFileType === 'All files' || selectedFileType === type;
    };

    // ─────────────────────────────────────────────────────────────
    //   GET WORKSPACE DATA + INVITES FROM CONTEXT
    // ─────────────────────────────────────────────────────────────
    useEffect(() => {
        // Wait until context finishes loading, and we have a valid workspaceId
        if (contextLoading || !workspaceId) return;

        // 1) Find the current workspace in `workspaces` by ID
        const currentWorkspace = workspaces.find((ws) => ws.id === workspaceId);
        if (currentWorkspace) {
            setWorkspaceUsers(currentWorkspace.team || []);
            setWorkspaceName(currentWorkspace.name);
        }

        // 2) Filter `invites` to those that match this workspaceId + contain user.email in `team`
        const workspaceInvites = invites.filter(
            (inv) =>
                inv.workspaceId === workspaceId &&
                inv.team?.includes(user.email)
        );

        // 3) Sort them by `order` ASC (so we maintain the original reorder logic)
        // If `order` is sometimes undefined, handle that carefully
        workspaceInvites.sort((a, b) => (a.order || 0) - (b.order || 0));

        // 4) Store them in local state
        setFiles(workspaceInvites);
    }, [contextLoading, workspaceId, invites, workspaces, user.email]);

    // Clean up on unmount:
    useEffect(() => {
        return () => {
            setShowNotesPanel(false);
        };
    }, []);

    // ─────────────────────────────────────────────────────────────
    //   DRAG AND DROP
    // ─────────────────────────────────────────────────────────────
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => prev + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => {
            const newCounter = prev - 1;
            if (newCounter === 0) {
                setIsDragging(false);
            }
            return newCounter;
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setDragCounter(0);

        const droppedFiles = e.dataTransfer.files;
        for (let i = 0; i < droppedFiles.length; i++) {
            const file = droppedFiles[i];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['mp3', 'wav', 'aiff'];

            if (allowedExtensions.includes(fileExtension)) {
                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
                uploadFile(file, fileNameWithoutExtension);
            } else {
                alert('Only mp3, wav, and aiff files are allowed.');
            }
        }
    };

    // ─────────────────────────────────────────────────────────────
    //   UPLOADING FILE
    // ─────────────────────────────────────────────────────────────
    const uploadFile = async (file, initialName) => {
        const fileSize = file.size;
        let averageLUFS = -Infinity;
        let audioDurationMs = 0;

        try {
            // 1) Calculate LUFS offline
            averageLUFS = await getAudioLufs(file);
            // 2) Also calculate the file's duration in milliseconds
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const arrayBuffer = await file.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            audioDurationMs = Math.floor(audioBuffer.duration * 1000);
        } catch (err) {
            console.error("Audio analysis failed:", err);
        }

        const checkAndUpload = httpsCallable(functions, 'checkAndUploadFile');

        try {
            // 3) Check storage limit via Cloud Function
            await checkAndUpload({
                fileName: file.name,
                fileSize: fileSize,
            });

            // 4) Proceed with file upload to Firebase Storage
            const storageRef = ref(storage, `files/${user.uid}/${Date.now()}_${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            const uploadingFile = {
                id: storageRef.fullPath,
                name: initialName,
                progress: 0,
            };
            setUploadingFiles((prev) => [...prev, uploadingFile]);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadingFiles((prevUploadingFiles) =>
                        prevUploadingFiles.map((uf) =>
                            uf.id === uploadingFile.id ? { ...uf, progress } : uf
                        )
                    );
                },
                async (error) => {
                    alert(error.message);
                    setUploadingFiles((prev) =>
                        prev.filter((uf) => uf.id !== uploadingFile.id)
                    );
                    // Roll back storageUsed in Firestore
                    const userDocRef = doc(db, 'users', user.uid);
                    await updateDoc(userDocRef, {
                        storageUsed: increment(-fileSize),
                    });
                },
                async () => {
                    // 5) Get the download URL
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    // 6) Create the doc with new lufs & durationMs
                    const invitationsRef = collection(db, 'invites');
                    const newInvitation = {
                        name: initialName,
                        userId: user.email,
                        workspaceId: workspaceId,
                        accepted: true,
                        time: serverTimestamp(),
                        lastUpdated: serverTimestamp(),
                        fileUrl: downloadURL,
                        type: 'sound',
                        team: workspaceUsers,
                        order: Date.now(),
                        lufs: averageLUFS,
                        durationMs: audioDurationMs,
                    };

                    const docRef = await addDoc(invitationsRef, newInvitation);
                    setUploadingFiles((prev) =>
                        prev.filter((uf) => uf.id !== uploadingFile.id)
                    );

                    // Optionally prompt rename after upload
                    setFilesPendingRename((prev) => [
                        ...prev,
                        {
                            id: docRef.id,
                            ...newInvitation,
                        },
                    ]);
                }
            );
        } catch (error) {
            if (error.code === 'functions/resource-exhausted') {
                alert(error.message);
            } else {
                alert('An error occurred during file upload.');
            }
        }
    };


    const handleUploadFileButton = () => {
        if (fileUploadInputRef.current) {
            fileUploadInputRef.current.click();
        }
    };

    const handleFileButtonChange = (e) => {
        const selectedFiles = e.target.files;
        if (!selectedFiles) return;

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const fileExtension = file.name.split(".").pop().toLowerCase();
            const allowedExtensions = ["mp3", "wav", "aiff"];

            if (allowedExtensions.includes(fileExtension)) {
                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
                uploadFile(file, fileNameWithoutExtension);
            } else {
                alert("Only mp3, wav, and aiff files are allowed.");
            }
        }
        e.target.value = null; // reset the input
    };


    // ─────────────────────────────────────────────────────────────
    //   RENAME LOGIC
    // ─────────────────────────────────────────────────────────────
    const handleRename = (id, newName) => {
        const invitationDocRef = doc(db, "invites", id);
        setDoc(invitationDocRef, { name: newName }, { merge: true })
            .then(() => {
                setFilesPendingRename((prev) => prev.filter((f) => f.id !== id));
            })
            .catch((error) => {
                alert(error);
            });
    };

    // ─────────────────────────────────────────────────────────────
    //   ADDING NEW USER
    // ─────────────────────────────────────────────────────────────
    const [newUserEmail, setNewUserEmail] = useState("");
    const [showAddUserPopup, setShowAddUserPopup] = useState(false);
    const addUserButtonRef = useRef(null);
    const popupRef = useRef(null);
    const [popupPosition, setPopupPosition] = useState({});

    const handleAddUserClick = () => {
        if (addUserButtonRef.current) {
            const rect = addUserButtonRef.current.getBoundingClientRect();
            setPopupPosition({
                top: rect.bottom + window.scrollY + 5,
            });
            setShowAddUserPopup(true);
        }
    };

    const handleAddUser = () => {
        const workspaceDocRef = doc(db, "workspaces", workspaceId);
        updateDoc(workspaceDocRef, {
            team: arrayUnion(newUserEmail)
        })
            .then(() => {
                setNewUserEmail("");
                setShowAddUserPopup(false);
                setShowEmailInput(false); // hide input again after adding
            })
            .catch((error) => {
                alert(error);
            });
    };


    const openProject = (id, name) => {
        // Mark this file as "read" for current user
        updateDoc(doc(db, 'invites', id), {
            unreadUsers: arrayRemove(user.email)
        });
        navigate(`/project/${id}/${name}`);
    };

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(e.target) &&
                addUserButtonRef.current &&
                !addUserButtonRef.current.contains(e.target)
            ) {
                setShowAddUserPopup(false);
            }
        };
        if (showAddUserPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAddUserPopup]);

    // ─────────────────────────────────────────────────────────────
    //   FILTER FILES
    // ─────────────────────────────────────────────────────────────
    const filteredFiles = files.filter((element) => {
        const matchesFileType =
            selectedFileType === 'All files' ||
            element.type.toLowerCase() === selectedFileType.toLowerCase();
        const matchesSearchTerm = element.name
            .normalize('NFC')
            .toLowerCase()
            .includes(searchTerm.normalize('NFC').toLowerCase());
        return matchesFileType && matchesSearchTerm;
    });

    // ─────────────────────────────────────────────────────────────
    //   REORDERING
    // ─────────────────────────────────────────────────────────────
    const [draggingFileId, setDraggingFileId] = useState(null);

    const reorderFilesInState = (startIndex, endIndex) => {
        const updatedFiles = Array.from(files);
        const [removed] = updatedFiles.splice(startIndex, 1);
        updatedFiles.splice(endIndex, 0, removed);
        setFiles(updatedFiles);
    };

    const handleDragEnd = async () => {
        setDraggingFileId(null);
        // Batch update the 'order' field
        const batch = writeBatch(db);
        files.forEach((file, index) => {
            const fileDocRef = doc(db, "invites", file.id);
            batch.update(fileDocRef, { order: index });
        });
        await batch.commit();
    };

    const handleToggleViewMode = () => {
        if (!noFilesOrProjects) {
            setViewMode((prevMode) => (prevMode === 'list' ? 'grid' : 'list'));
        }
    };

    // ─────────────────────────────────────────────────────────────
    //   POPUP FOR "UNSEEN CHANGES"
    // ─────────────────────────────────────────────────────────────
    const [unseenProjectsCount, setUnseenProjectsCount] = useState(0);
    const [showUnseenProjectsPopup, setShowUnseenProjectsPopup] = useState(false);

    useEffect(() => {
        // Count how many are unread for the current user
        const count = files.filter(
            (file) => file.unreadUsers?.includes(user.email)
        ).length;

        setUnseenProjectsCount(count);
    }, [files, user.email]);

    useEffect(() => {
        if (unseenProjectsCount > 0) {
            setShowUnseenProjectsPopup(true);
            const timer = setTimeout(() => {
                setShowUnseenProjectsPopup(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [unseenProjectsCount]);

    const noFilesOrProjects =
        !contextLoading &&
        (files.length === 0);


    const renderedMembers = Array.isArray(workspaceUsers) ? workspaceUsers.slice(0, 3) : [];
    const extraMembers = Array.isArray(workspaceUsers) && workspaceUsers.length > 3
        ? workspaceUsers.slice(3)
        : [];
    const extraMembersCount = extraMembers.length;


    // ─────────────────────────────────────────────────────────────
    //   RENDER
    // ─────────────────────────────────────────────────────────────
    return (
        <div
            className={`files__container ${isDragging ? 'dragging' : ''}`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {/* Overlaid dragging UI */}
            {isDragging && (
                <div className="files__dragOverlay">
                    <div className="files__dragOverlayContent">
                        <p>Drop files to upload</p>
                    </div>
                </div>
            )}

            {/* Header */}
            <div className="files__header">
                <div className="projects__title">
                    {workspaceName}
                </div>

                <div className="files__search">
                    <div className="files__searchcontainer">
                        <input
                            className="files__searchbar"
                            placeholder="Upload or search for an existing file..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <img
                            src={SearchIcon}
                            className="files__searchicon"
                            alt="Search Icon"
                        />
                    </div>
                </div>

                <div className="files__currentuser">
                    Folder Settings
                </div>
            </div>

            {/* Secondary header */}
            <div className="files__secondaryheader">
                <div
                    className="files__filetypeselector"
                    onMouseEnter={() => setIsHoveringFileTypeSelector(true)}
                    onMouseLeave={() => setIsHoveringFileTypeSelector(false)}
                >
                    {['All files', 'Sound', 'Photos', 'Videos'].map((type) => (
                        <div key={type}>
                            <div
                                className={`files__filetypebutton ${selectedFileType === type ? 'selected' : ''}`}
                                onClick={() => handleFileTypeSelect(type)}
                            >
                                {type}
                            </div>
                        </div>
                    ))}

                    <div className="files__filetypebuttonsmall">
                        <img
                            src={SoundType}
                            className="files__smallbutton"
                            onClick={() => handleFileTypeSelect('Sound')}
                            style={{
                                filter: isButtonActive('Sound') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Sound"
                        />
                        <img
                            src={PhotoType}
                            className="files__smallbutton"
                            onClick={() => handleFileTypeSelect('Photos')}
                            style={{
                                filter: isButtonActive('Photos') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Photos"
                        />
                        <img
                            src={VideoType}
                            className="files__smallbutton"
                            onClick={() => handleFileTypeSelect('Videos')}
                            style={{
                                filter: isButtonActive('Videos') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Videos"
                        />
                    </div>
                </div>

                <div className="files__users">
                    {renderedMembers.map((memberEmail) => (
                        <OneUserFiles key={memberEmail} userEmail={memberEmail} />
                    ))}

                    {/* Show +X if more than 3 */}
                    {workspaceUsers.length > 3 && (
                        <div className="files__moremembers"
                            onMouseEnter={() => setShowExtraHover(true)}
                            onMouseLeave={() => setShowExtraHover(false)}
                        >


                                +{extraMembersCount}


                            <AnimatePresence>
                                {showExtraHover && (
                                    <motion.div
                                        initial={{ opacity: 0, y: 0 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className="files__moremembers__popup"
                                    >
                                        {extraMembers.map((email) => (
                                            <div
                                                key={email}
                                                className="files__moremembers__row"
                                            >
                                                {email}
                                            </div>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    )}

                    {/* Toggle between list/grid mode */}
                    <button
                        className="files__adduserbutton"
                        onClick={handleToggleViewMode}
                    >
                        {viewMode === 'grid' ? (
                            <img
                                src={GridSettingSVG}
                                className="files__addmembersvg"
                                style={{ height: 17, transform: 'translate(0.5px, 2px)' }}
                                alt="Grid Mode"
                            />
                        ) : (
                            <img
                                src={ListSettingSVG}
                                className="files__addmembersvg"
                                style={{ height: 15, transform: 'translate(0.5px, 3px)' }}
                                alt="List Mode"
                            />
                        )}
                    </button>

                    {/* Button for adding new user */}
                    <button
                        ref={addUserButtonRef}
                        className="files__adduserbutton"
                        onClick={handleAddUserClick}
                    >
                        <img
                            src={AddMemberSVG}
                            className="files__addmembersvg"
                            style={{ height: 20 }}
                            alt="Add User"
                        />
                    </button>

                    <AnimatePresence>
                        {showAddUserPopup && (
                            <motion.div
                                onClick={(e) => e.stopPropagation()}
                                initial={{ opacity: 0, scale: 0.9, scaleY: 0.5, y: 0.0 }}
                                animate={{ opacity: 1, scale: 1, scaleY: 1, y: 1.0 }}
                                exit={{ opacity: 0, scale: 0.9, scaleY: 0.5 }}
                                transition={{ duration: 0.15 }}
                                className="files__addUserPopup"
                                ref={popupRef}
                                style={{
                                    top: popupPosition.top,
                                    left: popupPosition.left
                                }}
                            >
                                <div className="files__popupinfo">
                                    Add user by email or by link
                                </div>

                                <div className="files__addUserPopupButtons">
                                    <AnimatePresence>
                                        {showEmailInput && (
                                            <motion.div
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: 'auto', opacity: 1 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                                style={{ overflow: 'hidden' }}

                                            >
                                                <input
                                                    ref={emailInputRef}
                                                    type="email"
                                                    placeholder="User's email address"
                                                    value={newUserEmail}
                                                    onChange={(e) => setNewUserEmail(e.target.value)}
                                                    className="files__addUserInput"
                                                />
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                    <button
                                        onClick={handleAddUserByEmailClick}
                                        disabled={showEmailInput && !newUserEmail}
                                    >
                                        Add User By Email
                                    </button>

                                    {/* Share by Link */}
                                    <SideBarShareLinkButton
                                        className="colab__shareButton"
                                        ref={shareButtonRef}
                                        name={"Share By Link"}
                                        isSelected={showShareMenu}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowShareMenu(!showShareMenu);
                                        }}
                                        inviteLink={inviteLink}
                                        copyFunction={() => copyToClipboard(inviteLink)}
                                    />
                                </div>

                                {/* AnimatePresence to show/hide the email input */}

                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>

            {/* Files container */}
            <div className={`files__files ${viewMode === "grid" ? "" : "horizontal-layout"}`}>

                {noFilesOrProjects && uploadingFiles.length === 0 && (

                    <div className="invites__nofilesmessage"
                        style={{
                            width: '400px',
                            padding: '30px',
                            marginTop: '18vh',
                            marginLeft: 'calc(45% - 200px)',
                        }}
                    >
                        <div className='invites__nofilesmessage__bold'>
                            Oops! No files found.
                        </div>
                        <div className='invites__nofilesmessage__regular'>
                            Drop a file or use the button to add the first file into this folder
                        </div>



                        <div className="files__addUserPopupButtons">

                            <button className='files__addUserInput'
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '17px',
                                    marginTop: '10px',
                                    cursor: 'pointer'
                                }}
                                onClick={handleUploadFileButton}
                            >
                                + Add a file
                            </button>
                            <input
                                ref={fileUploadInputRef}
                                type="file"
                                accept=".mp3,.wav,.aiff"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleFileButtonChange}
                            />
                        </div>
                    </div>


                )}

                {/* Display filtered existing files */}
                {filteredFiles.map((element, index) => {
                    if (viewMode !== "list") {
                        return (
                            <OneFile
                                isProject={false}
                                key={element.id}
                                name={element.name}
                                id={element.id}
                                img={SkeletonSound}
                                isHoveringFileTypeSelector={isHoveringFileTypeSelector}
                                type={element.type}
                                members={element.team.length}
                                isUpdated={element.unreadUsers?.includes(user.email)}
                                lastUpdated={element.lastUpdated?.toDate()}
                                draggable
                                onDragStart={() => setDraggingFileId(element.id)}
                                onDragEnter={(e) => {
                                    e.stopPropagation();
                                    if (draggingFileId && draggingFileId !== element.id) {
                                        const draggedIndex = files.findIndex(
                                            (f) => f.id === draggingFileId
                                        );
                                        const targetIndex = files.findIndex(
                                            (f) => f.id === element.id
                                        );
                                        reorderFilesInState(draggedIndex, targetIndex);
                                    }
                                }}
                                onDragEnd={(e) => {
                                    e.stopPropagation();
                                    handleDragEnd();
                                }}
                            />
                        );
                    } else {
                        return (
                            <OneHorizontalFile
                                key={element.id}
                                name={element.name}
                                id={element.id}
                                url={SkeletonSound}
                                type={element.type}
                                isInFiles={true}
                                isUpdated={element.unreadUsers?.includes(user.email)}
                                members={element.team.length}
                                lastUpdated={element.lastUpdated?.toDate()}
                                onClick={() => openProject(element.id, element.name)}
                                draggable
                                onDragStart={() => setDraggingFileId(element.id)}
                                onDragEnter={(e) => {
                                    e.stopPropagation();
                                    if (draggingFileId && draggingFileId !== element.id) {
                                        const draggedIndex = files.findIndex(
                                            (f) => f.id === draggingFileId
                                        );
                                        const targetIndex = files.findIndex(
                                            (f) => f.id === element.id
                                        );
                                        reorderFilesInState(draggedIndex, targetIndex);
                                    }
                                }}
                                onDragEnd={(e) => {
                                    e.stopPropagation();
                                    handleDragEnd();
                                }}
                            />
                        );
                    }
                })}
            </div>

            {/* Notes panel button */}
            <button
                className="files__notesButton"
                onClick={() => setShowNotesPanel(true)}
            >
                <img src={NoteSVG} style={{ marginTop: noteNotification ? -1 : 3 }} className="files__notesbuttonsize" alt="Notes" />
            </button>

            {/* Notes Panel */}
            <AnimatePresence>
                {showNotesPanel && (
                    <motion.div
                        className="files__notesPanel"
                        initial={{ opacity: 0, scale: 0.9, scaleY: 0.1 }}
                        animate={{ opacity: 1, scale: 1, scaleY: 1.0 }}
                        exit={{ opacity: 0, scale: 0.9, y: 50 }}
                        transition={{ duration: 0.2 }}
                    >
                        <Notes
                            workspaceId={workspaceId}
                            user={user}
                            onClose={() => setShowNotesPanel(false)}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            {/* ─────────────────────────────────────────────────────────────
                POPUP: "X projects with unseen changes"
                ───────────────────────────────────────────────────────────── */}
            <AnimatePresence>
                {showUnseenProjectsPopup && unseenProjectsCount > 0 && (
                    <motion.div
                        initial={{ opacity: 0, y: 80 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 80 }}
                        transition={{ duration: 0.4 }}
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '12px 20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
                            fontWeight: '200',
                            fontSize: '14px',
                            zIndex: 9999
                        }}
                    >
                        {unseenProjectsCount} project
                        {unseenProjectsCount === 1 ? '' : 's'} with unseen changes
                    </motion.div>
                )}
            </AnimatePresence>

            {/* NEW: Popups for uploading files */}
            <AnimatePresence>
                {uploadingFiles.map((file, index) => (
                    <motion.div
                        key={file.id}
                        initial={{ opacity: 0, y: 60 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 60 }}
                        transition={{ duration: 0.4 }}
                        style={{
                            position: "fixed",
                            // Stagger them so they stack upward
                            bottom: `${20 + index * 60}px`,
                            right: "20px",
                            backgroundColor: "#333",
                            color: "#fff",
                            padding: "12px 20px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                            fontWeight: "500",
                            fontSize: "14px",
                            zIndex: 9999,
                            marginTop: "8px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                minWidth: "200px",
                            }}
                        >
                            <span>{file.name}</span>
                            <span>{Math.round(file.progress)}%</span>
                        </div>
                    </motion.div>
                ))}
            </AnimatePresence>
        </div >
    );
};

export default Files;
