import React, { useEffect, useState, useRef } from 'react';
import SoundType from './TypeSound.png';
import PhotoType from './TypePhoto.png';
import VideoType from './TypeVideo.png';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, updateDoc, doc, deleteDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';
import SkeletonSound from '../invites/SkeletonSound3.jpg'; // fallback if no image given
import { UserAuth } from '../../context/AuthContext';

const OneFile = (props) => {
    const { isProject = false } = props;
    const { plugin = false } = props
    const { isUpdated } = props

    const { unreadCount = 0 } = props

    const [name, setName] = useState(props.name);
    const initialName = props.name;
    const members = props.members;
    const type = props.type;
    const isRenaming = props.isRenaming;
    const onRename = props.onRename;
    const { isHoveringFileTypeSelector } = props;

      const { user } = UserAuth();

    // We still use SkeletonSound as fallback if no `props.img`.
    let imgSrc = props.img !== '' ? props.img : SkeletonSound;

    // For skeleton shimmer effect
    const [isImageLoading, setIsImageLoading] = useState(true);

    const navigate = useNavigate();
    const [timeFromChange, setTimeFromChange] = useState('');
    const [isEditing, setIsEditing] = useState(isRenaming);
    const inputRef = useRef(null);

    // Determine which icon to show for "type"
    let typeimg = SoundType;
    if (type === 'photo') {
        typeimg = PhotoType;
    } else if (type === 'video') {
        typeimg = VideoType;
    }

    // Time from last updated
    useEffect(() => {
        const updateTimeFromChange = () => {
            if (props.lastUpdated) {
                setTimeFromChange(formatRelativeTime(props.lastUpdated));
            }
        };
        updateTimeFromChange();

        const intervalId = setInterval(() => {
            updateTimeFromChange();
        }, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [props]);

    const formatRelativeTime = (date) => {
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        let value;
        let unit;

        if (diffInSeconds < 60) {
            value = diffInSeconds;
            unit = 'second';
        } else if (diffInSeconds < 3600) {
            value = Math.floor(diffInSeconds / 60);
            unit = 'minute';
        } else if (diffInSeconds < 86400) {
            value = Math.floor(diffInSeconds / 3600);
            unit = 'hour';
        } else if (diffInSeconds < 2592000) {
            value = Math.floor(diffInSeconds / 86400);
            unit = 'day';
        } else if (diffInSeconds < 31536000) {
            value = Math.floor(diffInSeconds / 2592000);
            unit = 'month';
        } else {
            value = Math.floor(diffInSeconds / 31536000);
            unit = 'year';
        }

        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        return rtf.format(-value, unit);
    };

    // Open the project when not renaming
    const openProject = (id, name) => {
        if (plugin) {
            let duration = 0;
            if (props.duration) {
                duration = props.duration;
            }
            navigate(`/plugin/${id}/${name}/${duration}/1`);
        }
        else if (!isRenaming && !isProject) {
            updateDoc(doc(db, "invites", id), {
                unreadUsers: arrayRemove(user.email),
            });
            navigate(`/project/${id}/${name}`);
        }
    };

    // Editing project name
    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    const handleBlur = async () => {
        setIsEditing(false);
        if (name !== initialName) {
            // Write to Firestore
            await renameDocument(name);
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
            if (name !== initialName) {
                await renameDocument(name);
            }
        }
    };

    // ─────────────────────────────────────────────────────────────
    // SHARE / INVITE LINK LOGIC
    // ─────────────────────────────────────────────────────────────
    const [showShareButton, setShowShareButton] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [showInvitePopup, setShowInvitePopup] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const shareButtonRef = useRef(null);
    const shareMenuRef = useRef(null);

    useEffect(() => {
        if (showInvitePopup && !inviteLink) {
            generateInviteLink();
        }
    }, [showInvitePopup]);

    const renameDocument = async (newName) => {
        if (isProject) {
            await updateDoc(doc(db, 'workspaces', props.id), {
                name: newName,
                lastUpdated: serverTimestamp(),
            });
        } else {
            await updateDoc(doc(db, 'invites', props.id), {
                name: newName,
                lastUpdated: serverTimestamp(),
            });
        }
    };

    const handleRename = () => {
        setIsEditing(true);
        setShowShareMenu(false)
    };


    const handleDelete = async () => {
        if (isProject) {
            await deleteDoc(doc(db, 'workspaces', props.id));
        } else {
            await deleteDoc(doc(db, 'invites', props.id));
        }
    };

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);


    // This function checks whether we're inviting to a file or a project
    const generateInviteLink = async () => {
        try {
            // random token
            const token = Math.random().toString(36).substr(2);

            // If this is a *project*, store doc in "projectInvites"
            // Otherwise, store doc in "fileInvites"
            let inviteCollectionName = 'fileInvites';
            let docData = {
                token,
                isUsed: false,
                createdAt: serverTimestamp(),
            };

            if (isProject) {
                inviteCollectionName = 'projectInvites';
                // we assume props.id is the workspaceId in this scenario
                docData.projectId = props.id;
            } else {
                docData.fileId = props.id;
            }

            const inviteRef = collection(db, inviteCollectionName);
            await addDoc(inviteRef, docData);

            // Build the link
            // If isProject => /projectinvite/:token
            // If not => /invite/:token
            const pathPrefix = isProject ? 'projectinvite' : 'invite';
            const link = `${window.location.origin}/${pathPrefix}/${token}`;

            setInviteLink(link);
        } catch (error) {
            console.error('Error generating invite link:', error);
        }
    };

    return (
        <motion.div
            className='files__onefilecontainer'
            onClick={() => openProject(props.id, name)}
            draggable={props.draggable}
            onDragStart={props.onDragStart}
            onDragEnter={props.onDragEnter}
            onDragEnd={props.onDragEnd}
            onContextMenu={(e) => {
                e.preventDefault();
                setShowShareMenu(true);
            }}
            onMouseEnter={() => setShowShareButton(true)}
            onMouseLeave={() => {
                setShowShareButton(false);
                setShowShareMenu(false);
            }}
            animate={{
                scale: showShareButton ? 1.015 : 1,
                zIndex: showShareButton ? 3 : 1
            }}
            transition={{ duration: 0.01 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
        >



            {unreadCount > 0 && (

                <motion.div
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 10,
                        duration: 1.5
                    }}
                    initial={{ opacity: 0, scale: 0.0 }}
                    whileInView={{ opacity: 1, scale: 1.0 }}
                    viewport={{ once: true }}
                    className='files__isupdated'
                >

                    <div className="onefile__badge">
                        {unreadCount}
                    </div>



                </motion.div>
            )
            }
            {/* File Type Icon */}
            <AnimatePresence>
                {isHoveringFileTypeSelector && (
                    <motion.div
                        className="files__onefiletype"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <img
                            src={typeimg}
                            className="files__onefiletypenondraggable"
                            style={{ width: '20px' }}
                            alt="Type"
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Image Container + Skeleton */}
            <div className="files__onefileimg" style={{ position: 'relative' }}>
                {/* SKELETON SHIMMER */}
                <div className='files__onefileimgsize'>

                    <AnimatePresence>
                        {isImageLoading && (
                            <motion.div
                                className="files__onefileimgskeleton"
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{
                                    position: 'absolute',
                                    top: 20,
                                    left: 20,
                                    width: '83%',
                                    height: '88%',
                                    overflow: 'hidden',
                                    borderRadius: 4,
                                    backgroundColor: '#f2f2f2',
                                }}
                            >
                                {/* The moving beam */}
                                <motion.div
                                    initial={{ x: '-100%' }}
                                    animate={{ x: '100%' }}
                                    transition={{
                                        repeat: Infinity,
                                        duration: 1.5,
                                        ease: 'linear'
                                    }}
                                    style={{
                                        width: '50%',
                                        height: '100%',
                                        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent)',
                                    }}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {/* Actual Image */}
                    <img
                        className='files__onefileimgsize'
                        src={imgSrc}
                        alt=""
                        style={{
                            opacity: isImageLoading ? 0 : 1,
                            transition: 'opacity 0.3s ease-in-out',
                        }}
                        onLoad={() => setIsImageLoading(false)}
                        onError={() => setIsImageLoading(false)}
                    />
                </div>

                {/* Share Button */}
                {showShareButton && !plugin && (
                    <motion.button
                        className="files__shareButton"
                        ref={shareButtonRef}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowShareMenu(!showShareMenu);
                        }}
                    >
                        Share
                    </motion.button>
                )}

                {/* Share Menu */}
                <AnimatePresence>
                    {showShareMenu && (
                        <motion.div
                            className="files__shareMenu"
                            ref={shareMenuRef}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div
                                className="files__shareMenuItem"
                                onClick={() => {
                                    setShowInvitePopup(true);
                                    setShowShareMenu(false);
                                }}
                            >
                                Invite by Link
                            </div>

                            <div
                                className="files__shareMenuItem"
                                onClick={handleRename}
                            >
                                Rename
                            </div>

                            <div
                                className="files__shareMenuItem"
                                onClick={handleDelete}
                            >
                                Delete
                            </div>
                            {/* Additional share options could go here */}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {/* Filename / Editing */}
            <div className="files__onefilename">
                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPress}
                    />
                ) : (
                    name
                )}
            </div>

            {/* Members */}
            {
                members && (
                    <div className="files__onefilemembers">
                        {members} Members
                    </div>
                )
            }

            {/* Time Since Last Update */}
            {
                timeFromChange && (
                    <div className="files__onefiletime">
                        {timeFromChange}
                    </div>
                )
            }

            {/* Invite Popup */}
            <AnimatePresence>
                {showInvitePopup && (
                    <motion.div
                        className="files__invitePopup"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 0.2 }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="files__invitePopupContent">
                            <h3>Share this link</h3>
                            {inviteLink ? (
                                <>
                                    <input
                                        type="text"
                                        value={inviteLink}
                                        readOnly
                                        onClick={(e) => e.target.select()}
                                    />
                                    <button onClick={() => setShowInvitePopup(false)}>
                                        Close
                                    </button>
                                </>
                            ) : (
                                <>
                                    <p>Generating link...</p>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div >
    );
};

export default OneFile;
