// ColabMessages.jsx

import React, { useState, useEffect } from 'react';
import ColabMessage from './ColabMessage';
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  serverTimestamp,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import ColabSendSVG from './Colabsend.svg';
import Pause from './PauseSound.svg';
import Play from './playsound.svg';
import { useParams } from 'react-router-dom';
import { additionalHexData } from './additionalHexData.js';

import { motion } from 'framer-motion';

const ColabMessages = ({
  waveWidth,
  duration,
  viewWidth,
  projectData,
  time,
  playState,
  togglePlayState,
  goToComment,
  getCurrentTime,
  memberPanelOpen,
  users,
}) => {
  const [allComments, setAllComments] = useState([]);
  const [currentCommentValue, setCurrentCommentValue] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  // replyingTo holds { id, userName, message } of the parent comment we're replying to

  const { user } = UserAuth();
  const params = useParams();
  const uid = user.uid;
  let pluginid = projectData.pluginId;
  const allMembers = projectData.team;

  if (!pluginid) {
    pluginid = '';
  }

  useEffect(() => {
    if (user.uid) {
      const q = query(
        collection(db, 'comments'),
        where('project', '==', params.id),
        orderBy('time', 'asc')
      );

      onSnapshot(q, (snapshot) => {
        setAllComments(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              viewing: false,
              ...doc.data(),
            };
          })
        );
      });
      markAsRead();
    }
  }, [user.uid]);

  async function markAsRead() {
    // Implementation for reading comments, if desired
  }

  const deleteComment = async (id) => {
    try {
      await deleteDoc(doc(db, 'comments', id));
    } catch (error) {
      console.error('Error deleting comment: ', error);
    }
  };

  const formatTimecode = (time) => {
    const totalSeconds = parseInt(time, 10);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
    const milliseconds = Math.floor((time % 1) * 1000);

    const pad = (num, size = 2) => num.toString().padStart(size, '0');

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(milliseconds, 3)}`;
  };

  const handleClick = () => {
    togglePlayState();
  };

  const onChange = (event) => {
    setCurrentCommentValue(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewComment();
    }
  };

  const addNewComment = () => {
    const allEmailsExceptMine = allMembers.filter((email) => email !== user.email);

    if (currentCommentValue) {
      const newComment = {
        value: currentCommentValue,
        user: uid,
        project: params.id,
        time: serverTimestamp(),
        position: getCurrentTime(),
        pluginId: pluginid,
        col: allMembers.indexOf(user.email),
        username: user.displayName,
        readby: [user.email],
        replyTo: replyingTo?.id || null, // <-- Store the parent comment ID if we're replying
      };

      setDoc(doc(collection(db, 'comments')), newComment).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });

      // Update invites doc to mark other members as having an unread comment
      updateDoc(doc(db, 'invites', params.id), {
        unreadUsers: arrayUnion(...allEmailsExceptMine),
      });

      setCurrentCommentValue('');
      setReplyingTo(null); // reset the reply state after sending
    }
  };

  // "Reply" function invoked from the child component (ColabMessage)
  const handleReply = (parentComment) => {
    // parentComment = { id, userName, message }
    setReplyingTo(parentComment);
    // Optionally focus the textarea or scroll to bottom
    const textareaEl = document.getElementById('colab-textarea');
    if (textareaEl) {
      textareaEl.focus();
    }
  };

  return (
    <div style={{ position: 'relative', zIndex: '1' }}>
      <div className='colab__comments'>
        {allComments.map((element) => (
          <div key={element.id}>
            <div className='colab__onecomment'>
              <div
                className='colab__whitepos'
                style={{
                  width: `${(element.position * waveWidth * 1.001) / duration +
                    (viewWidth - 650) * 0.01
                    }px`,
                }}
              ></div>
              <div onClick={() => goToComment(element.position)}
                
                >
                <ColabMessage
                  message={element.value}
                  position={element.position}
                  color={element.col}
                  commentId={element.id}
                  deleteComment={deleteComment}
                  memberPanelOpen={memberPanelOpen}
                  reactions={element.reactions || {}}
                  currentUserEmail={user.email}
                  userName={element.username}
                  users={users}
                  replyTo={element.replyTo || null}       // pass the parent comment ID if any
                  allComments={allComments}               // so we can look up the parent comment
                  onReply={handleReply}                   // callback to set replyingTo
                  time={element.time}
                  user={element.user}
                />
              </div>
        
            </div>
          </div>
        ))}

        <div className='bottomspace'></div>
      </div>

      <div className='colab__bottomcontainer'>
        <div className='colab__plusbutton' onClick={handleClick}>
          <div className='colab__playpos'>
            {playState && <img className='colab__play' src={Pause} alt='Pause' />}
            {!playState && (
              <img
                className='colab__play'
                src={Play}
                alt='Play'
                style={{ marginLeft: 3, marginTop: 1 }}
              />
            )}
          </div>
        </div>

        <div className='colab__bottomleftgap'></div>

        <div className='colab__textarea'>
          <div className='colab__time'>{formatTimecode(time)}</div>

          {/* If we are replying to a comment, show which comment here */}
          {replyingTo && (
            <div className='colab__replyinginfo'>
              <span>
                <strong>Replying to {replyingTo.userName}:</strong> "{replyingTo.message}"
              </span>
              <button
                className='colab__replyinginfo-cancel'
                onClick={() => setReplyingTo(null)}
              >
                X
              </button>
            </div>
          )}

          <textarea
            id='colab-textarea'
            spellCheck={false}
            autoCorrect='false'
            className='colab__text'
            value={currentCommentValue}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            placeholder='Add a comment...'
          ></textarea>

          <button className='colab__sendbutton' onClick={addNewComment}>
            <img src={ColabSendSVG} alt='Send' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColabMessages;
